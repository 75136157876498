@import 'assets/scss/top-theme.scss';
@import 'variables.scss';

html, body {
  font-size: 14px;
  margin: 0;
  height: 100%;
  background-color: #ffffff;
  font-family: "Open Sans", Regular;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.mat-mdc-input-element {
  caret-color: $base-color;
}

::ng-deep .mat-mdc-input-element:active {
  caret-color: $base-color;
}

::ng-deep .mat-mdc-input-element:focus {
  caret-color: $base-color;
}

.mat-mdc-raised-button {
  background-color: $base-color;
  color: $font-color;
}

.mdc-button {
  padding: 0 16px !important;

  .mdc-button__label {
    letter-spacing: normal;
  }
}

.mat-mdc-icon-button {
  background-color: white;
  color: $font-color;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: contents !important;

  &[disabled] {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-mdc-tab-label-container {
  border-bottom: 1px solid #cacccf !important;
}

.mat-mdc-tab {
  flex-grow: 0 !important;
}

.mdc-tab {
  min-width: 160px !important;
  padding: 0 24px !important;
  .mdc-tab__text-label {
    letter-spacing: normal;
  }
}

.mat-calendar-body-selected {
  background-color: $base-color;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 0.5em 0;
}

::ng-deep {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white;
  }

  mat-form-field {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-hint, input, ::placeholder, .mat-form-field-label {
      color: white;
    }
  }
}


/**Custom Scrollbar*/

/*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar:after {
  overflow: hidden;
}

::-webkit-scrollbar-thumb:window-inactive {
  overflow: hidden;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(112, 112, 112, 1);
  border-radius: 10px;
  background-color: $font-color;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(112, 112, 112, 1);
  background-color: rgba(112, 112, 112, 1);;
}*/

/**overwrite mat-form-field outline border */

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $secondary-color;
}


/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $base-color;
}

::ng-deep.not-empty-select.mat-mdc-selected {
  color: green !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
:host ::ng-deep .mat-select-value-text {
  color: green !important;
}

.sign-snackbar {
  background-color: #b5bcc5;
  color: #0A2240;
  border: solid #0A2240;
}
.sign-snackbar .mat-mdc-snack-bar-action {
  color: #3b983d;
}

/* top dashboard overlays */

.inactivity-dialog-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  border: none;
  overflow: hidden;
}

.inactivity-dialog-container .dialog-item {
  background: white;
  opacity: .9;
  margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 10px;
  width: 350px;
}

.inactivity-dialog-container .dialog-title {
  background: $base-color;
  color: white;
  border-radius: 5px 5px 0 0;
  margin-top: 0px;
  padding: 10px;
}

.inactivity-dialog-container .dialog-btn {
  margin-bottom: 10px;
}
