$base-color: #0A2240;
$secondary-color: #FF7500;
$font-color: #ffffff;

$navigation-font-family: Segoe UI, Regular;
$base-font-family: Open Sans, Regular;
$base-font-regular: Open Sans, Regular;





