@import "variables";

.top-advert-dialog-container .mat-mdc-dialog-container {
  width: 94em;
  height: 30em;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.top-advert-presetting {
  &__header {
    width: 100%;
    background-color: #3b4e66;
    color: white;
  }
  &__text {
    flex: 1 1 auto;
    font-family: OpenSans, serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-left: 20px;

    letter-spacing: 0;
    text-align: left;
    color: #ffffff;
    &-inquiry {
      text-align: center;
    }
    &-customer {
      text-align: right;
    }
  }
  &__button {
    margin-left: 20px;

    &.mat-mdc-raised-button {
      background: $white;
      color: $dusk;
      border-color: $dusk;
      padding: 0px 16px;
      margin-bottom: 10px;
      width: 100%;
      &.mat-accent {
        background-color: $dusty-orange;
        border: none;
        color: $white;
      }
      &.mat-primary:not([disabled]) {
        background: $dusk;
        color: $white;
        border: none;
      }
      &.mat-primary .mat-icon {
        color: #fff;
        width: 30px;
      }
    }
  }
  &__title {
    font-family: OpenSans, serif;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0;
    text-align: left;
  }

  &__center {
    overflow: hidden;
    width: 98%;
    justify-self: center;
    align-items: center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  &toggle-button-group, .mat-button-toggle-group {
    border-radius: 20px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  &toggle-button-group, .mat-button-toggle-checked {
    background-color: $dusk;
    color: $white;
  }

  &__toggle-button {
    width: 8em;
  }

  &__card &__job-type {
    width: 80% !important;
    border-radius: 2px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

  &__client {
    margin-top: 10%;
  }

  &__footer {
    justify-self: center;
    align-items: center;
  }

  &__card-title {
    font-family: OpenSans, serif;
    font-size: 18px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  &__job-checkbox.mat-checkbox-checked {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-background {
      background-color: $dusk !important;
    }
  }

  &__presetting-card, .mat-mdc-card {
    width: calc(100% - 70px);
    height: calc(100% - 60px);
    align-content: center;
  }

  &__job-family {
    justify-content: center;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-checked{
  color: #ffffff !important;
  background-color: #3b4e66;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked{
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-background {
    background-color: #3b4e66 !important;
  }
}

.top-advert-textDialog, .mat-mdc-dialog-container {
  padding: 0 !important;
}

.top-advert-textDialog {
  justify-self: center;
  overflow: hidden;

  &__header {
    width: 100%;
    background-color: #3b4e66;
    color: white;
  }

  &__title {
    font-family: OpenSans, serif;
    font-size: 1em;
    text-align: left;
  }

  &__center {
    overflow: hidden;
    width: 98%;
    justify-self: center;
    align-items: center;
  }

  &__textarea {
    width: 95%;
    height: 70%;
    margin-left: 1em;
  }

  &__button {
    float: right;

    &.mat-mdc-raised-button {
      background: $white;
      color: $dusk;
      border-color: $dusk;
      padding: 0px 16px;
      margin-bottom: 10px;

      &.mat-accent {
        background-color: $dusty-orange;
        border: none;
        color: $white;
      }

      &.mat-primary:not([disabled]) {
        background: $dusk;
        color: $white;
        border: none;
      }

      &.mat-primary .mat-icon {
        color: #fff;
        width: 30px;
      }
    }
  }
}


