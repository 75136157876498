$base-color: #0A2240;
$secondary-color: #FF7500;
$font-color: #ffffff;
$greyBackBorder: #707070;
$green: #41B945;
$border-color: #ced3d9;
$text-color: #3b4e66;
$very-light-blue: #e6e8ec;
$white: #ffffff;
$dusk: #3b4e66;
$battleship-grey: #6c7a8c;
$midnight: #040f1c;
$cloudyBlue: #b5bcc5;
$dusty-orange: #f28c38;
$slate: #475970;
$royal: #0d1793;
$violetBlue: #6a0da6;
$mid-green: #3b983d;
$golden: #e9d106;
$red: #d41100;

$dark-primary-text: lighter-contrast;
$light-primary-text: white;

$navigation-font-family: 'Open Sans', Regular;
$base-font-family: 'Open Sans', Regular;
$base-font-regular: 'Open Sans', Regular;

$border-radius: 8px;
$font-family: 'Open Sans', sans-serif;

// FONTS - Families
$font-family: 'Open Sans', sans-serif, Regular;
$navigation-font-family: 'Open Sans', Regular;
$base-font-family: 'Open Sans', Regular;
$base-font-regular: 'Open Sans', Regular;
$semi-bold: 600;
